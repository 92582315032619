.pagination {
    display: inline-block;
    list-style: none;
  }

.item_class{
    display: inline;
    border-radius: 5px;
    margin: 3px;
  }

.item_class :hover:not(.active){
    background-color: #ddd;
}
.pg_active{
    background-color: #4CAF50;
    color: white !important;
    border-radius: 5px;
}

  .link_class {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border-radius: 5px;
  }
